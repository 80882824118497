import { uniqBy, sortBy, findIndex } from 'lodash';
import { FETCH_CATEGORIES_SUCCESS } from '../../common/actions/fetch-categories';
import { CREATE_CATEGORY_SUCCESS } from '../actions/create-category';
import {
  CATEGORIES_MANAGER_CREATE_CATEGORY,
  CATEGORIES_MANAGER_DELETE_CATEGORY,
  CATEGORIES_MANAGER_UPDATE_CATEGORY,
} from '../actions/categories-manager-actions-constants';
import { DELETE_CATEGORY_SUCCESS } from '../actions/delete-category';
import { UPDATE_CATEGORY_SUCCESS } from '../actions/update-category';
import { CATEGORY_ADD_GROUP, CATEGORY_REMOVE_GROUP } from '../actions/private-category-actions';
import { SET_CATEGORIES } from '../actions/set-categories';
import { UPDATE_CATEGORY_COUNTERS } from '../actions/category-socket';
import { SUBSCRIBE_REQUEST, SUBSCRIBE_FAILURE } from '../actions/subscribe';
import { UNSUBSCRIBE_REQUEST, UNSUBSCRIBE_FAILURE } from '../actions/unsubscribe';
import { FETCH_CATEGORY_SUCCESS } from '../actions/fetch-category';
import { UPDATE_CATEGORIES } from '../actions/update-categories';
import { FETCH_CATEGORIES_GROUPS_SUCCESS } from '../../common/actions/fetch-categories-groups';

const initialState = [];
const SORT_BY = 'rank';

export default (state = initialState, { type, payload, meta } = {}) => {
  switch (type) {
    case FETCH_CATEGORIES_GROUPS_SUCCESS:
      return state.map(category => ({
        ...category,
        groups: payload[category._id] || category.groups,
      }));
    case UPDATE_CATEGORIES:
      return payload;
    case FETCH_CATEGORIES_SUCCESS:
      return sortBy(uniqBy([...payload, ...state], '_id'), SORT_BY);
    case FETCH_CATEGORY_SUCCESS:
    case CREATE_CATEGORY_SUCCESS:
    case CATEGORIES_MANAGER_CREATE_CATEGORY:
      return sortBy(uniqBy([payload, ...state], '_id'), SORT_BY);
    case UPDATE_CATEGORY_COUNTERS:
    case UPDATE_CATEGORY_SUCCESS: {
      const categoryIndex = findIndex(state, category => category._id === payload._id);
      const category = state[categoryIndex];
      return category
        ? sortBy(
            [
              ...state.slice(0, categoryIndex),
              {
                ...category,
                ...payload,
              },
              ...state.slice(categoryIndex + 1, state.length),
            ],
            SORT_BY,
          )
        : state;
    }
    case CATEGORIES_MANAGER_UPDATE_CATEGORY: {
      const categoryIndex = findIndex(state, category => category._id === payload._id);
      const category = state[categoryIndex];
      return category
        ? sortBy(
            [
              ...state.slice(0, categoryIndex),
              {
                ...(!meta.replace && category),
                ...payload,
              },
              ...state.slice(categoryIndex + 1, state.length),
            ],
            SORT_BY,
          )
        : state;
    }
    case DELETE_CATEGORY_SUCCESS:
    case CATEGORIES_MANAGER_DELETE_CATEGORY:
      return state.filter(category => category._id !== payload);
    case SET_CATEGORIES:
      return [...payload];
    case SUBSCRIBE_REQUEST:
      return setIsSubscribed(state, payload, true);

    case SUBSCRIBE_FAILURE:
      return setIsSubscribed(state, payload, false);

    case UNSUBSCRIBE_REQUEST:
      return setIsSubscribed(state, payload, false);

    case UNSUBSCRIBE_FAILURE:
      return setIsSubscribed(state, payload, true);
    case CATEGORY_ADD_GROUP: {
      const categoryIndex = findIndex(state, category => category._id === payload.categoryId);
      const category = state[categoryIndex];
      if (category) {
        return sortBy(
          [
            ...state.slice(0, categoryIndex),
            {
              ...category,
              groups: [...(category.groups || []), ...payload.groups],
            },
            ...state.slice(categoryIndex + 1, state.length),
          ],
          SORT_BY,
        );
      }
      return state;
    }
    case CATEGORY_REMOVE_GROUP: {
      const categoryIndex = findIndex(state, category => category._id === payload.categoryId);
      const category = state[categoryIndex];
      if (category) {
        return sortBy(
          [
            ...state.slice(0, categoryIndex),
            {
              ...category,
              groups: category.groups.filter(groupId => !payload.groups.includes(groupId)),
            },
            ...state.slice(categoryIndex + 1, state.length),
          ],
          SORT_BY,
        );
      }
      return state;
    }
    default:
      return state;
  }
};

function setIsSubscribed(state, { type, _id }, isSubscribed) {
  if (type !== 'category') {
    return state;
  }

  const index = findIndex(state, category => category._id === _id);
  return [
    ...state.slice(0, index),
    {
      ...state[index],
      isSubscribed,
    },
    ...state.slice(index + 1),
  ];
}
