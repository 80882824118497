import { get, find, filter } from 'lodash';

const getComments = state => state.comments;

const getEntityIdsByPage = (state, type, paginationId, page) => {
  return get(state, `pagination[${type}][${paginationId}].entitiesByPage[${page}]`, []);
};

export const getCommentsByPostId = (state, postId) =>
  (getComments(state)[postId] || []).filter(comment => !comment.parentId);
export const getCommentsByPostIdAndPage = (state, postId, page) => {
  const entityIds = getEntityIdsByPage(state, 'comments', postId, page);
  const comments = getCommentsByPostId(state, postId);
  return filter(comments, comment => entityIds.indexOf(comment._id) > -1);
};

export const getRepliesToCommentByPostId = (state, postId, commentId) =>
  (getComments(state)[postId] || []).filter(comment => comment.parentId === commentId);

export const getComment = (state, commentId, postId) =>
  find(getCommentsByPostId(state, postId), comment => comment._id === commentId);

export const getAnyComment = (state, commentId, postId) =>
  find(getComments(state)[postId] || [], comment => comment._id === commentId);

export const getCommentsIdsThatHaveReplies = comments =>
  comments
    .filter(comment => get(comment, 'replyCount', 0) > 0 || comment.hasReplies)
    .map(comment => comment._id);

export const getCommentsIdsThatHaveRepliesByPostId = (state, postId) =>
  getCommentsIdsThatHaveReplies(getCommentsByPostId(state, postId));

export const getVoteCount = comment => comment.upvoteCount - comment.downvoteCount || 0;
